import Result from '@defs/Result'
import { OTPsSendResponse, StytchAPIError, StytchError, StytchUIClient } from '@stytch/vanilla-js'

export default async function sendSms(
    stytch: StytchUIClient,
    phoneNumber: string,
): Promise<Result<string>> {
    let result: OTPsSendResponse

    try {
        result = await stytch.otps.sms.send(phoneNumber, {
            expiration_minutes: 5,
        })
    } catch (err) {
        const errorMessage = 'Unfortunately we were unable to send an SMS to you, please try again'

        const castErr = err as StytchError

        if (!('error_type' in castErr)) {
            return { success: false, error: errorMessage }
        }

        const apiError = err as StytchAPIError

        if (apiError.error_type === 'duplicate_phone_number') {
            return {
                success: false,
                error: 'A user already exists with this phone number, if this is you, please use that account',
            }
        }
        if (apiError.error_type === 'invalid_phone_number') {
            return {
                success: false,
                error: 'We did not recognise your phone number, please review the entered number and try again',
            }
        }
        if (apiError.error_type === 'invalid_phone_number_country_code') {
            return {
                success: false,
                error: 'Sorry, we did not recognise the country code used or it is not supported via our authentication partner',
            }
        }
    }

    return { success: true, value: result!.method_id }
}
