import Months from '../constants/Months'

function sameDay(startDate: string, endDate: string) {
    return startDate.split('T')[0] === endDate.split('T')[0]
}

function sameMonth(startDate: string, endDate: string) {
    return (
        startDate.split('-')[1] === endDate.split('-')[1] &&
        startDate.split('-')[0] === endDate.split('-')[0]
    )
}

function sameYear(startDate: string, endDate: string) {
    return startDate.split('-')[0] === endDate.split('-')[0]
}

export default function formatDate({ startDate, endDate }: { startDate: string; endDate: string }) {
    const startDateAsDate = new Date(startDate)
    const endDateAsDate = new Date(endDate)

    if (sameDay(startDate, endDate)) {
        return `${startDateAsDate.getDate()} ${
            Months[startDateAsDate.getMonth()]
        } ${startDateAsDate.getFullYear()}`
    }
    if (sameMonth(startDate, endDate)) {
        return `${startDateAsDate.getDate()} - ${endDateAsDate.getDate()} ${
            Months[endDateAsDate.getMonth()]
        } ${endDateAsDate.getFullYear()}`
    }
    if (sameYear(startDate, endDate)) {
        return `${startDateAsDate.getDate()} ${
            Months[startDateAsDate.getMonth()]
        } - ${endDateAsDate.getDate()} ${
            Months[endDateAsDate.getMonth()]
        } ${endDateAsDate.getFullYear()}`
    }
    return `${startDateAsDate.getDate()} ${
        Months[startDateAsDate.getMonth()]
    } ${startDateAsDate.getFullYear()} - ${endDateAsDate.getDate()} ${
        Months[endDateAsDate.getMonth()]
    } ${endDateAsDate.getFullYear()}`
}
