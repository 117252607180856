import { GetEventImageMapResponse } from '@commonwealthventures/image-service-api-models'
import Result from '@defs/Result'
import sleep from '@utils/sleep'
import _ from 'lodash'
import getImageMap from './getImageMap.service'
import { ImageField } from './getS3SignedUrl.service'

export default async function checkImageMapFieldsHaveUpdated({
    eventId,
    fields,
    originalImageMap,
    maxRetries,
    onFieldVerified,
    retryCount = 0,
}: {
    eventId: string
    fields: ImageField[]
    originalImageMap: GetEventImageMapResponse
    maxRetries: number
    onFieldVerified: (field: ImageField) => void
    retryCount?: number
}): Promise<Result<void>> {
    let updatedMap: GetEventImageMapResponse

    try {
        updatedMap = await getImageMap(eventId)
    } catch (err) {
        return {
            success: false,
            error: `Error validating that images at paths ${fields.join(
                ', ',
            )} was uploaded. Failed to get image map from server`,
        }
    }

    const unresolved = fields.filter((field) => {
        const formattedKey = field.split('/').join('.')
        const originalKey = _.get(originalImageMap, formattedKey)
        const mapKey = _.get(updatedMap, formattedKey)

        if (mapKey && mapKey !== originalKey) {
            onFieldVerified(field)
            return false
        }

        return true
    })

    if (unresolved.length === 0) {
        return {
            success: true,
        }
    }

    if (retryCount < maxRetries) {
        await sleep(500)
        return checkImageMapFieldsHaveUpdated({
            eventId,
            fields: unresolved,
            originalImageMap,
            maxRetries,
            onFieldVerified,
            retryCount: retryCount + 1,
        })
    }

    return {
        success: false,
        error: `Timed out waiting for images at paths ${fields.join(', ')} to updated`,
    }
}
