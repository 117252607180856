import { Container, VStack } from '@chakra-ui/react'
import { EventStatusV1 } from '@commonwealthventures/poc-readstore-v1-models'
import capitalizeSentence from '@utils/capitalizeSentence'
import EventQueryingPage, { EventWithImages } from '@v1_hocs/EventQueryingPage.component'
import SocialButtons from '@v1_molecules/SocialButtons/SocialButtons.component'
import EventTitle from '../../atoms/EventTitle/EventTitle.component'
import FundImage from '../../atoms/FundImage/FundImage.component'
import SchpielText from '../../atoms/SchpielText/SchpielText.component'
import SuccessImage from '../../atoms/SuccessImage/SuccessImage.component'

function SuccessPageContents({ data }: { data: EventWithImages }) {
    const { status, title, images, requiredTotalOfPledgedAttendees, currentAttendees } = data

    const thanksText =
        status === EventStatusV1.REQUIRES_FUNDING
            ? `You have successfully pledged to attend ${capitalizeSentence(
                  title,
              )}. This event is one step closer to reality, thanks to you. Spread the word.`
            : `You are going to ${capitalizeSentence(
                  title,
              )}. This event is happening because of you. Spread the word.`

    const shareUrl = window.location.href.split('/').slice(0, -1).join('/')
    const shareMessage =
        status === EventStatusV1.REQUIRES_FUNDING
            ? `I just pledged to go attend ${title} on Turbafi and made it one step closer to reality! We only need ${
                  requiredTotalOfPledgedAttendees - currentAttendees
              } more people and this event can go ahead!`
            : `I am going to ${title}! Come and join me!`

    return (
        <Container p="0">
            <VStack spacing="6">
                <SuccessImage src={images['thank-you']} alt="Thank you for pledging" />
                <VStack p="6" alignItems="start" spacing="4">
                    <EventTitle text="Thank you!" />
                    <SchpielText text={thanksText} />
                    <Container mt="4" p="0">
                        <SocialButtons shareUrl={shareUrl} message={shareMessage} />
                    </Container>
                </VStack>
            </VStack>
            <FundImage src={images.primary} />
        </Container>
    )
}

export default function SuccessPage() {
    return (
        <EventQueryingPage>
            {(data: EventWithImages) => <SuccessPageContents data={data} />}
        </EventQueryingPage>
    )
}
