import Result from '@defs/Result'
import axios, { AxiosError } from 'axios'
import getS3SignedUrl, { ImageField } from './getS3SignedUrl.service'

export default async function uploadImage({
    eventId,
    field,
    file,
    authToken,
}: {
    eventId: string
    field: ImageField
    file: File
    authToken: string
}): Promise<Result<void>> {
    const { success, value, error } = await getS3SignedUrl(eventId, field, authToken)

    if (!success) {
        return {
            success: false,
            error,
        }
    }

    try {
        await axios.put(value!.uploadUrl, file, {
            headers: {
                'Content-Type': file.type,
                'x-amz-tagging': value!.tagHeaderToSet,
            },
        })
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error uploading image. ERROR:', (err as AxiosError).toJSON())

        return {
            success: false,
            error: 'failed to upload image',
        }
    }

    return {
        success: true,
    }
}
