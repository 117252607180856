import Result from '@defs/Result'
import { useStytch } from '@stytch/react'
import AuthenticateCodeForm, {
    AuthenticateCodeFormData,
} from '@v1_atoms/AuthenticateCodeForm/AuthenticateCodeForm.component'
import { useNavigate } from 'react-router-dom'

interface AuthenticateOtpProps {
    redirectTo: string
    sendMethodId: string
}

function AuthenticateOtp({ redirectTo, sendMethodId }: AuthenticateOtpProps) {
    const stytch = useStytch()

    const navigate = useNavigate()

    const authenticateOtp = async (formData: AuthenticateCodeFormData): Promise<Result<void>> => {
        try {
            await stytch.otps.authenticate(Object.values(formData.code).join(''), sendMethodId, {
                session_duration_minutes: 60,
            })

            navigate(redirectTo)
        } catch (err) {
            const errorMessage =
                'Failed to authenticate OTP. Please double check the SMS code we sent you'

            return {
                success: false,
                error: errorMessage,
            }
        }

        return {
            success: true,
        }
    }

    return <AuthenticateCodeForm authenticate={authenticateOtp} />
}

export default AuthenticateOtp
