import { VStack } from '@chakra-ui/react'
import formatDate from '@utils/formatDate'
import formatToHoursAndMins from '@utils/formatHoursAndMinutes'
import IconText from '@v1_atoms/IconText/IconText.component'
import SectionTitle, { SectionTitleType } from '@v1_atoms/SectionTitle/SectionTitle.component'
import { MdAccessTime, MdDateRange } from 'react-icons/md'

export type DatesType = { startDate: string; endDate: string }[]

function DateSection({ dates }: { dates: DatesType }) {
    return (
        <VStack w="full" alignItems="start" spacing="2">
            <SectionTitle text="Dates" type={SectionTitleType.KEY_DETAIL} />
            <VStack alignItems="start" spacing="2">
                {dates.map(({ startDate, endDate }) => (
                    <>
                        <IconText text={formatDate({ startDate, endDate })} icon={MdDateRange} />
                        <IconText
                            text={formatToHoursAndMins({ startDate, endDate })}
                            icon={MdAccessTime}
                        />
                    </>
                ))}
            </VStack>
        </VStack>
    )
}

export default DateSection
