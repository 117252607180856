import { z } from 'zod'
import OrganiserDetailsSchema from './OrganiserDetailsSchema'
import AccessMetadataSchema from './AccessMetadataSchema'

const TrustedMetadataSchema = z.object({
    access: AccessMetadataSchema.optional(),
    organiserDetails: OrganiserDetailsSchema.optional(),
})

export default TrustedMetadataSchema
