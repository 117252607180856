import { ChevronDownIcon } from '@chakra-ui/icons'
import {
    Box,
    FormControl,
    FormErrorMessage,
    HStack,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    VStack,
} from '@chakra-ui/react'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { Controller, useFormContext } from 'react-hook-form'

const countryCodeToIsoCode: { [s: string]: { iso: string; name: string } } = {
    '+44': {
        iso: 'GB',
        name: 'United Kingdom',
    },
}

interface PhoneNumberInputProps<T extends { [s: string]: unknown }> {
    name: keyof T
}

function PhoneNumberInput<T extends { [s: string]: unknown }>({ name }: PhoneNumberInputProps<T>) {
    const { control, formState, setValue, trigger } = useFormContext()
    const [selectedCountryCode, setSelectedCountryCode] = useState('+44')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [touched, setTouched] = useState(false)

    const isInvalid = !!_.get(formState.errors, name)

    useEffect(() => {
        if (!selectedCountryCode || phoneNumber.trim() === '') {
            setValue(name as string, '')

            if (touched) {
                trigger(name as string)
            }
        }

        if (selectedCountryCode && phoneNumber.trim() !== '') {
            const trimmedPhone = phoneNumber.trim()
            const formattedPhone = trimmedPhone.replace(/^0/, '')

            setValue(name as string, `${selectedCountryCode}${formattedPhone}`)
            trigger(name as string)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCountryCode, phoneNumber])

    return (
        <Controller
            control={control}
            name={name as string}
            rules={{
                validate: (val) => {
                    if (val === '') {
                        return 'You must enter a phone number'
                    }

                    if (!/^\+(?:[0-9] ?){6,14}[0-9]$/.test(val)) {
                        return 'You must enter a valid phone number'
                    }

                    return true
                },
            }}
            render={() => (
                <VStack w="full">
                    <HStack w="full">
                        <Menu>
                            <MenuButton
                                as={Box}
                                px={2}
                                py="0.425rem"
                                borderWidth="1px"
                                borderStyle="solid"
                                borderColor="plejGreen.500"
                                borderRadius="0.375rem"
                                cursor="pointer"
                                _hover={{ borderColor: 'plejGreen.200' }}
                            >
                                <HStack>
                                    <ReactCountryFlag
                                        countryCode={countryCodeToIsoCode[selectedCountryCode].iso}
                                        aria-label={`flag of ${countryCodeToIsoCode[selectedCountryCode].name}`}
                                    />
                                    <Text>{selectedCountryCode}</Text>
                                    <ChevronDownIcon />
                                </HStack>
                            </MenuButton>

                            <MenuList>
                                <MenuItem onClick={() => setSelectedCountryCode('+44')}>
                                    United Kingdom (+44)
                                </MenuItem>
                            </MenuList>
                        </Menu>
                        <Input
                            type="tel"
                            variant="createEventGreen"
                            onChange={(e) => setPhoneNumber(e.currentTarget.value)}
                            onBlur={() => {
                                setTouched(true)
                                trigger(name as string)
                            }}
                        />
                    </HStack>
                    <FormControl isInvalid={!!_.get(formState.errors, name)}>
                        {isInvalid ? (
                            <FormErrorMessage>
                                {_.get(formState.errors, name)?.message?.toString() ?? ''}
                            </FormErrorMessage>
                        ) : null}
                    </FormControl>
                </VStack>
            )}
        />
    )
}

export default PhoneNumberInput
