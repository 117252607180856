import { Grid, GridItem, Spinner, Text, VStack } from '@chakra-ui/react'
import { EventV1 } from '@commonwealthventures/poc-readstore-v1-models'
import EventResultItem from '@v1_organisms/EventResultItem/EventResultItem.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import axios from 'axios'
import { useQuery } from 'react-query'

function Events() {
    const { data, error, isLoading } = useQuery<EventV1[]>({
        queryKey: ['EVENTS_V1'],
        queryFn: async () => {
            const result = await axios.get(`${process.env.REACT_APP_DATASTORE_API_URL}/v1/events`, {
                headers: {
                    'x-api-key': process.env.REACT_APP_READSTORE_API_KEY!,
                },
            })

            return result.data
        },
        retry: 0,
    })

    if (error) {
        return (
            <VStack minH="100vh" spacing={4} pb="4" color="plejGreen.500" bg="plejGrey.300">
                <NavHeading menuColour="green" headingText="Turbafi" />
                <VStack justifyContent="center" flex={1}>
                    <Text>Sorry. An error occurred loading events.</Text>
                </VStack>
            </VStack>
        )
    }

    if (isLoading) {
        return (
            <VStack minH="100vh" spacing={4} pb="4" color="plejGreen.500" bg="plejGrey.300">
                <NavHeading menuColour="green" headingText="Turbafi" />
                <VStack justifyContent="center" flex={1}>
                    <Spinner size="xl" thickness="4px" speed="0.75s" />
                    <Text fontSize="xl">Loading...</Text>
                </VStack>
            </VStack>
        )
    }

    return (
        <VStack minH="100vh" spacing={4} pb="4" color="plejGreen.500" bg="plejGrey.300">
            <NavHeading menuColour="green" headingText="Turbafi" />
            <Grid
                px="4"
                templateColumns={{
                    base: 'repeat(1, 1fr)',
                    md: 'repeat(2, 1fr)',
                    lg: 'repeat(3, 1fr)',
                    xl: 'repeat(4, 1fr)',
                }}
                gap="4"
                w="full"
            >
                {data!.map((event) => (
                    <GridItem>
                        <EventResultItem event={event} />
                    </GridItem>
                ))}
            </Grid>
        </VStack>
    )
}

export default Events
