import { Box, Container, Heading, Link, Text, VStack } from '@chakra-ui/react'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'

function ContactPage() {
    const contactUsWords =
        'If you want to make an event happen, have feedback, or just want to say hi, speak with us. We’re listening! :)'

    return (
        <Container p="0">
            <VStack spacing="6" minH="100vh">
                <Box
                    w="100vw"
                    h="100vh"
                    position="fixed"
                    zIndex="-1"
                    left="0px"
                    top="0px"
                    bgColor="F6F3F3"
                />
                <NavHeading menuColour="green" headingText="Turbafi" />
                <Heading as="h2" fontSize="24" textColor="plejGreen.500" fontWeight="medium" pt="4">
                    Contact us
                </Heading>
                <Text px="4" textColor="plejGreen.500">
                    {contactUsWords}
                </Text>
                <Link
                    href="mailto:sean@commonwealthventures.co.uk?Subject=Working with Turbafi"
                    textColor="plejGreen.500"
                >
                    Email Sean at sean@commonwealthventures.co.uk
                </Link>
                {/* <ContactForm /> */}
                <VStack textColor="plejGreen.500" fontSize="sm" spacing="0" py="8">
                    <Text>Turbafi</Text>
                    <Text>12 Jordan Street</Text>
                    <Text>Liverpool</Text>
                    <Text>L1 0BP</Text>
                </VStack>
            </VStack>
        </Container>
    )
}

export default ContactPage
