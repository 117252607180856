import { useStytchSession, useStytchUser } from '@stytch/react'
import { Navigate } from 'react-router-dom'
import ChallengeMfaRoute from '@constants/ChallengeMfaRoute'
import ConfigureMfaRoute from '@constants/ConfigureMfaRoute'
import checkSessionHasBeenMfaChecked from '@helpers/authentication/checkSessionHasBeenMfaChecked'
import ConfigureSmsRoute from '@constants/ConfigureSmsRoute'
import LoginRoute from '@constants/LoginRoute'

function ProtectedPage({
    children,
    checkMfa = true,
    requireSmsSetup = true,
}: {
    children: JSX.Element
    checkMfa?: boolean
    requireSmsSetup?: boolean
}) {
    const { user } = useStytchUser()
    const { session } = useStytchSession()

    if (!user || !session || new Date(session.expires_at).getTime() < Date.now()) {
        return <Navigate to={LoginRoute} />
    }

    if (checkMfa) {
        const verifiedTotp = user.totps.find(({ verified }) => verified)

        if (!verifiedTotp) {
            return <Navigate to={ConfigureMfaRoute} />
        }

        const mfaSession = checkSessionHasBeenMfaChecked(session)

        if (!mfaSession) {
            return <Navigate to={ChallengeMfaRoute} />
        }
    }

    if (requireSmsSetup) {
        const verifiedPhoneNumber = user.phone_numbers.find(({ verified }) => verified)

        if (!verifiedPhoneNumber) {
            return <Navigate to={ConfigureSmsRoute} />
        }
    }

    return children
}

export default ProtectedPage
