import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import DefaultRedirectOnLogin from '@constants/DefaultRedirectOnLogin'
import AddPhoneNumber from '@v1_molecules/AddPhoneNumber/AddPhoneNumber.component'
import ProtectedPage from '@v1_hocs/ProtectedPage.component'
import AuthenticateOtp from '@v1_molecules/AuthenticateOtp/AuthenticateOtp.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import { useEffect, useState } from 'react'

function ConfigureSmsBody() {
    const [enableResend, setEnableResend] = useState(false)
    const [smsSentMethodId, setSmsSentMethodId] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (smsSentMethodId) {
            setEnableResend(false)

            setTimeout(() => {
                if (smsSentMethodId) {
                    setEnableResend(true)
                }
            }, 5000)
        }
    }, [smsSentMethodId])

    return (
        <VStack w="full">
            <NavHeading menuColour="green" headingText="Turbafi" />

            <VStack
                spacing={8}
                maxW="800px"
                w="full"
                textColor="plejGreen.500"
                alignItems="start"
                mb="6"
            >
                <Heading as="h2">Add your phone number</Heading>
                {smsSentMethodId ? (
                    <>
                        <Text>
                            We&apos;ve sent you an SMS with a code in to validate your number. This
                            will be valid for 5 mins
                        </Text>
                        <AuthenticateOtp
                            redirectTo={DefaultRedirectOnLogin}
                            sendMethodId={smsSentMethodId}
                        />
                        <Button
                            onClick={() => setSmsSentMethodId(undefined)}
                            variant="textButton"
                            size="md"
                            mt={-4}
                            disabled={!enableResend}
                        >
                            I did not receive a message
                        </Button>
                    </>
                ) : (
                    <>
                        <Text>
                            We need your phone number in case you lose access to your authentication
                            app
                        </Text>
                        <AddPhoneNumber onSubmit={(id: string) => setSmsSentMethodId(id)} />
                    </>
                )}
            </VStack>
        </VStack>
    )
}

export default function ConfigureSms() {
    return (
        <ProtectedPage requireSmsSetup={false}>
            <ConfigureSmsBody />
        </ProtectedPage>
    )
}
