import { CreateFundBody, CreateFundResponse } from '@commonwealthventures/poc-admin-api-models'
import Result from '@defs/Result'
import axios from 'axios'
import axiosRetry from 'axios-retry'

export default async function createFund(
    details: CreateFundBody,
    jwt: string,
): Promise<Result<string>> {
    let eventId: string

    try {
        const result = await axios.post<CreateFundResponse>(
            `${process.env.REACT_APP_ADMIN_API_URL}/fund`,
            details,
            {
                headers: {
                    'x-API-Key': process.env.REACT_APP_ADMIN_API_KEY!,
                    Authorization: jwt,
                },
            },
        )

        eventId = result.data.id
    } catch (err) {
        return {
            success: false,
            error: 'Failed to create fund',
        }
    }

    const client = axios.create({ baseURL: process.env.REACT_APP_DATASTORE_API_URL! })
    axiosRetry(client, {
        retries: 30,
        retryDelay: axiosRetry.linearDelay(1000),
        retryCondition: () => true,
    })

    try {
        await client.get(`/v1/events/${eventId}`, {
            headers: {
                'x-api-key': process.env.REACT_APP_READSTORE_API_KEY!,
            },
        })
    } catch (err) {
        return {
            success: false,
            error: 'Failed to verify that event was created',
        }
    }

    return {
        success: true,
        value: eventId,
    }
}
