import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import ConfigureMfaRoute from '@constants/ConfigureMfaRoute'
import DefaultRedirectOnLogin from '@constants/DefaultRedirectOnLogin'
import { useStytchUser } from '@stytch/react'
import RecoverTotp from '@v1_atoms/RecoverTotp/RecoverTotp.component'
import ProtectedPage from '@v1_hocs/ProtectedPage.component'
import AuthenticateTotp from '@v1_molecules/AuthenticateTotp/AuthenticateTotp.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

function ChallengeMfaBody() {
    const { user } = useStytchUser()
    const navigate = useNavigate()

    const [showRecoveryPath, setShowRecoveryPath] = useState(false)

    const checkIfUserHasTotpSetupAlready = () => {
        if (user!.totps.length > 0) {
            const verifiedTotp = user!.totps.find(({ verified }) => verified)

            if (!verifiedTotp) {
                // If they haven't got a QR code verified then they need to return to the verification stage
                // it might be they need a new QR code or just to finish setting up the last one
                navigate(ConfigureMfaRoute)
            }
        } else {
            // If they haven't got a QR code configured then they need to go get one
            navigate(ConfigureMfaRoute)
        }
    }

    useEffect(() => {
        checkIfUserHasTotpSetupAlready()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <VStack w="full">
            <NavHeading menuColour="green" headingText="Turbafi" />

            <VStack
                spacing={8}
                maxW="800px"
                w="full"
                textColor="plejGreen.500"
                alignItems="start"
                mb="6"
            >
                {showRecoveryPath ? (
                    <>
                        <Heading as="h2">Reset your authentication using a recovery code</Heading>
                        <Text>
                            Enter one of the recovery codes you saved when configuring your account
                        </Text>
                        <RecoverTotp />
                    </>
                ) : (
                    <>
                        <Heading as="h2">Log in using your authenticator app</Heading>
                        <Text>
                            Enter the 6-digit verification code generated by your authenticator app.
                        </Text>
                        <AuthenticateTotp
                            redirectTo={DefaultRedirectOnLogin}
                            isSetupProcess={false}
                        />
                        <Button
                            onClick={() => setShowRecoveryPath(true)}
                            variant="textButton"
                            size="md"
                            mt={-4}
                        >
                            I no longer have access to my authenticator app
                        </Button>
                    </>
                )}
            </VStack>
        </VStack>
    )
}

export default function ChallengeMfa() {
    return (
        <ProtectedPage checkMfa={false} requireSmsSetup={false}>
            <ChallengeMfaBody />
        </ProtectedPage>
    )
}
