import AccessReference from '@defs/AccessReference'
import TrustedMetadata from '@defs/TrustedMetadata'
import TrustedMetadataSchema from '@schemas/TrustedMetadataSchema'
import _ from 'lodash'

export default function checkAccess(
    trustedMetadata: Record<string, unknown> | undefined,
    requiredAccess: AccessReference[],
): boolean {
    if (requiredAccess.length === 0) {
        return true
    }

    if (!trustedMetadata) {
        return false
    }

    let parsedTrustedMetadata: TrustedMetadata

    try {
        parsedTrustedMetadata = TrustedMetadataSchema.parse(trustedMetadata)
    } catch (err) {
        return false
    }

    if (!parsedTrustedMetadata.access) {
        return false
    }

    const access = parsedTrustedMetadata.access!

    const missingAccess = requiredAccess.filter(
        (accessProperty) => _.get(access, accessProperty) !== true,
    )

    if (missingAccess.length > 0) {
        return false
    }

    return true
}
