import {
    Button,
    FormControl,
    FormErrorMessage,
    HStack,
    PinInput,
    PinInputField,
    VStack,
} from '@chakra-ui/react'
import Result from '@defs/Result'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

interface AuthenticateCodeFormProps {
    authenticate: (data: AuthenticateCodeFormData) => Promise<Result<void>>
}

export type AuthenticateCodeFormData = {
    code: string[]
}

function AuthenticateCodeForm({ authenticate }: AuthenticateCodeFormProps) {
    const { handleSubmit, control, formState, trigger } = useForm<AuthenticateCodeFormData>({
        mode: 'onBlur',
    })
    const [isSubmitting, setIsSubmitting] = useState(false)

    const [authenticateCodeError, setAuthenticateCodeError] = useState<string | undefined>()

    const submitForm = async (formData: AuthenticateCodeFormData) => {
        setIsSubmitting(true)

        const { success, error } = await authenticate(formData)

        if (!success) {
            setAuthenticateCodeError(error)
        } else {
            setAuthenticateCodeError(undefined)
        }

        setIsSubmitting(false)
    }

    const handlePinBlur = (e: React.FocusEvent) => {
        const nextFocus = e.relatedTarget as HTMLElement
        const isMovingWithinPinInput = nextFocus?.getAttribute('data-pin-input')

        // Only trigger validation if focus is completely moving outside the PinInput
        if (!isMovingWithinPinInput) {
            trigger('code')
        }
    }

    return (
        <form onSubmit={handleSubmit(submitForm)}>
            <VStack>
                <FormControl isInvalid={!!formState.errors.code || !!authenticateCodeError}>
                    <Controller
                        name="code"
                        control={control}
                        defaultValue={['', '', '', '', '', '']}
                        rules={{
                            validate: (value) =>
                                Object.values(value).join('').length === 6 ||
                                'Please enter all 6 digits',
                        }}
                        render={({ field }) => (
                            <HStack spacing={2}>
                                <PinInput
                                    otp
                                    size="lg"
                                    onChange={(value) =>
                                        field.onChange({ ...field.value, ...value.split('') })
                                    }
                                    onComplete={(value) =>
                                        field.onChange({ ...field.value, ...value.split('') })
                                    }
                                >
                                    {new Array(6).fill(undefined).map((_, idx) => (
                                        <PinInputField
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={`code${idx}`}
                                            data-pin-input
                                            onBlur={handlePinBlur}
                                        />
                                    ))}
                                </PinInput>
                            </HStack>
                        )}
                    />
                    <FormErrorMessage>
                        {authenticateCodeError || (formState.errors.code?.message ?? '')}
                    </FormErrorMessage>
                </FormControl>
                <Button mt={4} type="submit" isLoading={isSubmitting}>
                    Submit
                </Button>
            </VStack>
        </form>
    )
}

export default AuthenticateCodeForm
