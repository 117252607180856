import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import ConfigureMfaRoute from '@constants/ConfigureMfaRoute'
import DefaultRedirectOnLogin from '@constants/DefaultRedirectOnLogin'
import { useStytch, useStytchSession, useStytchUser } from '@stytch/react'
import ProtectedPage from '@v1_hocs/ProtectedPage.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import { useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

function ResetMfaBody() {
    const stytch = useStytch()
    const { user } = useStytchUser()
    const { session } = useStytchSession()
    const navigate = useNavigate()

    const [deleteError, setDeleteError] = useState<string | undefined>()

    if (!user || !session) {
        return <Text>Loading...</Text>
    }

    const sessionInRecoveryMode = session!.authentication_factors.find(
        ({ delivery_method, type }) => delivery_method === 'recovery_code' && type === 'totp',
    )

    if (!sessionInRecoveryMode) {
        return <Navigate to={DefaultRedirectOnLogin} />
    }

    const resetMfa = async () => {
        if (!user) {
            setDeleteError(
                "Sorry we can't process resetting your MFA right now, try refreshing the page or try again later",
            )
        }

        const totpToDelete = user!.totps.find(({ verified }) => verified)

        if (!totpToDelete) {
            setDeleteError(
                `Failed to find your current TOTP config, please try again or go to ${ConfigureMfaRoute}`,
            )
        }

        try {
            await stytch.user.deleteTOTP(totpToDelete!.totp_id)
        } catch (err) {
            setDeleteError('Failed to delete your current TOTP, please try again')
            return
        }

        localStorage.removeItem('totp')

        setDeleteError(undefined)
        navigate(ConfigureMfaRoute)
    }

    return (
        <VStack w="full">
            <NavHeading menuColour="green" headingText="Turbafi" />
            <VStack spacing={8} maxW="600px" w="full" textColor="plejGreen.500" alignItems="start">
                <Heading as="h2">Reset MFA</Heading>
                {deleteError ? <Text color="red.500">{deleteError}</Text> : null}
                <Button onClick={resetMfa}>I no longer have access to my authenticator app</Button>
                <Button
                    onClick={() => navigate(DefaultRedirectOnLogin)}
                    variant="outline"
                    _hover={{ backgroundColor: 'plejGreen.100' }}
                >
                    I still have access to my app, I just didn&apos;t have it this time
                </Button>
            </VStack>
        </VStack>
    )
}

export default function ResetMfa() {
    return (
        <ProtectedPage>
            <ResetMfaBody />
        </ProtectedPage>
    )
}
