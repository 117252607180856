import { Button, FormControl, FormErrorMessage, VStack } from '@chakra-ui/react'
import sendSms from '@services/sendSms.service'
import { useStytch } from '@stytch/react'
import { StytchUIClient } from '@stytch/vanilla-js'
import PhoneNumberInput from '@v1_atoms/PhoneNumberInput/PhoneNumberInput.component'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

type PhoneNumForm = {
    phoneNumber: string
}

interface AddPhoneNumberProps {
    onSubmit: (id: string) => void
}

function AddPhoneNumber({ onSubmit }: AddPhoneNumberProps) {
    const stytch = useStytch()
    const methods = useForm<PhoneNumForm>()

    const [submitError, setSubmitError] = useState<string | undefined>()
    const [sendingSms, setSendingSms] = useState(false)

    const addPhoneNumber = async (formData: PhoneNumForm) => {
        setSendingSms(true)

        const {
            success,
            value: methodId,
            error,
        } = await sendSms(stytch as StytchUIClient, formData.phoneNumber)

        setSendingSms(false)

        if (!success) {
            setSubmitError(error)
        }

        onSubmit(methodId!)
    }

    return (
        <FormProvider {...methods}>
            <form style={{ width: '100%' }} onSubmit={methods.handleSubmit(addPhoneNumber)}>
                <VStack w="full" spacing={6}>
                    <PhoneNumberInput<PhoneNumForm> name="phoneNumber" />
                    {submitError ? (
                        <FormControl isInvalid={!!submitError}>
                            <FormErrorMessage>{submitError}</FormErrorMessage>
                        </FormControl>
                    ) : null}
                    <Button type="submit" isLoading={sendingSms}>
                        Send SMS
                    </Button>
                </VStack>
            </form>
        </FormProvider>
    )
}

export default AddPhoneNumber
