import {
    Box,
    Button,
    Heading,
    Spinner,
    Text,
    Textarea,
    useClipboard,
    VStack,
} from '@chakra-ui/react'
import ConfigureMfaRoute from '@constants/ConfigureMfaRoute'
import DefaultRedirectOnLogin from '@constants/DefaultRedirectOnLogin'
import { useStytch } from '@stytch/react'
import { TOTPRecoveryCodesResponse as StytchTOTPRecoveryCodesResponse } from '@stytch/vanilla-js'
import ProtectedPage from '@v1_hocs/ProtectedPage.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import { useEffect, useRef, useState } from 'react'
import { MdCheck, MdOutlineContentCopy } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

type TOTPRecoveryCodesResponse = {
    totps: Array<StytchTOTPRecoveryCodesResponse['totps']>
}

function RecoveryCodesBody() {
    const stytch = useStytch()
    const { hasCopied, setValue, onCopy } = useClipboard('')
    const requestedRecoveryCodes = useRef(false)
    const navigate = useNavigate()

    const [recoveryCodes, setRecoveryCodes] = useState<string[]>([])
    const [error, setError] = useState<string | undefined>()

    const getRecoveryCodes = async () => {
        let response: TOTPRecoveryCodesResponse

        try {
            // Stytches def is wrong
            response = (await stytch.totps.recoveryCodes()) as unknown as TOTPRecoveryCodesResponse
        } catch (err) {
            setError('Unable to retrieve authentication codes, please reloading the page')
            return
        }

        const verifiedTotp = response!.totps.find((totp) => totp.verified)

        if (!verifiedTotp) {
            navigate(ConfigureMfaRoute)
            return
        }

        setRecoveryCodes(verifiedTotp.recovery_codes)
    }

    useEffect(() => {
        if (!requestedRecoveryCodes.current) {
            requestedRecoveryCodes.current = true
            getRecoveryCodes()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setValue(recoveryCodes.join('\n'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recoveryCodes])

    return (
        <VStack w="full">
            <NavHeading menuColour="green" headingText="Turbafi" />

            <VStack
                spacing={8}
                maxW="800px"
                w="full"
                textColor="plejGreen.500"
                alignItems="start"
                mb="6"
            >
                <Heading as="h2">Your recovery codes</Heading>
                <Text>
                    Make a secure note of these, should you lose access to your device for
                    generating TOTP codes you will need to use these instead.
                </Text>

                {error ? <Text color="red.500">{error}</Text> : null}

                {recoveryCodes ? (
                    <>
                        <Box w="full" mx="auto" position="relative">
                            <Textarea
                                pr="4.5rem"
                                value={recoveryCodes.join('\n')}
                                readOnly
                                resize="none"
                                rows={10}
                            />
                            <Button
                                position="absolute"
                                top={2}
                                right={2}
                                w="4.5rem"
                                h="1.9rem"
                                px={12}
                                py={2}
                                borderRadius={4}
                                size="sm"
                                leftIcon={hasCopied ? <MdCheck /> : <MdOutlineContentCopy />}
                                onClick={onCopy}
                                colorScheme="plejGrey"
                                color="plejGreen.500"
                                fontWeight="medium"
                            >
                                {hasCopied ? 'Copied!' : 'Copy'}
                            </Button>
                        </Box>
                        <Button
                            cursor="pointer"
                            as="a"
                            onClick={() => navigate(DefaultRedirectOnLogin)}
                        >
                            I&apos;ve made a note
                        </Button>
                    </>
                ) : (
                    <Spinner />
                )}
            </VStack>
        </VStack>
    )
}

export default function RecoveryCodes() {
    return (
        <ProtectedPage requireSmsSetup={false}>
            <RecoveryCodesBody />
        </ProtectedPage>
    )
}
