import { PostImageResponse } from '@commonwealthventures/image-service-api-models'
import Result from '@defs/Result'
import axios, { AxiosError } from 'axios'

export type ImageField =
    | 'primary'
    | 'thank-you'
    | `providers/${string}`
    | 'organiser'
    | 'confirm/big'
    | 'confirm/medium'
    | 'confirm/small'

export default async function getS3SignedUrl(
    eventId: string,
    field: ImageField,
    authToken: string,
): Promise<
    Result<
        Pick<PostImageResponse, 'tagHeaderToSet' | 'uploadUrl'> & {
            httpMethod: 'put' | 'post' | 'delete'
        }
    >
> {
    try {
        const generatePresignedUrlResult = await axios.post<PostImageResponse>(
            `${process.env.REACT_APP_ADMIN_API_URL}/fund/${eventId}/images/${field}`,
            null,
            {
                headers: {
                    'x-API-Key': process.env.REACT_APP_ADMIN_API_KEY!,
                    Authorization: authToken,
                    'Content-Type': 'application/json',
                },
            },
        )

        const { httpMethod, uploadUrl, tagHeaderToSet } = generatePresignedUrlResult.data

        const formattedHttpMethod = httpMethod.toLowerCase() as 'put' | 'post' | 'delete'

        return {
            success: true,
            value: {
                httpMethod: formattedHttpMethod,
                uploadUrl,
                tagHeaderToSet,
            },
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error generating signed url. ERROR:', (err as AxiosError).toJSON())

        return { success: false, error: 'failed to generate signed url' }
    }
}
