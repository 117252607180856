import { Box, Button, Image, VStack } from '@chakra-ui/react'
import InfoSection from '@v1_atoms/InfoSection/InfoSection.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import MockEvent from '../../../mocks/Event'
import SchpielText from '../../atoms/SchpielText/SchpielText.component'

function HomePage() {
    const catchyOneLiner = 'Make anything happen.'
    const introSentence =
        'Turbafi is the best way for event creators  to discover, pitch, de-risk, and price optimise any event with their audience.'
    const longerSentence =
        'Leveraging audience interest into committed spend is fragile, flakey, and fraught with “drop off”.'
    const otherSentence =
        'We help you find the best way to event for your audience, and even *your audience* to find the best way to event *you*.'

    const maxCenterWidth = '800px'

    return (
        <VStack spacing="6" color="plejGreen.500" bg="plejGrey.300" w="100vw" minH="100vh">
            <NavHeading menuColour="green" headingText="Turbafi" />
            <InfoSection maxCenterWidth={maxCenterWidth}>
                <SchpielText text={catchyOneLiner} textColour="plejGreen.500" textAlign="center" />
            </InfoSection>
            <InfoSection bg="plejGreen.500" maxCenterWidth={maxCenterWidth}>
                <SchpielText text={introSentence} textAlign="center" />
                <Button textColor="plejGreen.500" variant="white" as="a" href="/login">
                    Get started
                </Button>
            </InfoSection>
            <InfoSection maxCenterWidth={maxCenterWidth}>
                <SchpielText text={longerSentence} textColour="plejGreen.500" textAlign="center" />
            </InfoSection>
            <InfoSection bg="plejGreen.500" maxCenterWidth={maxCenterWidth} flex="1">
                <Box pt="10" position="relative">
                    <Image
                        borderRadius="full"
                        boxSize="90px"
                        src={MockEvent.images.primary.path}
                        alt={MockEvent.images.primary.alt}
                        zIndex="1"
                        // Account for parent padding
                        top="-85px"
                        position="absolute"
                        left="50%"
                        marginLeft="-45px"
                    />
                    <SchpielText text={otherSentence} textAlign="center" />
                </Box>
            </InfoSection>
        </VStack>
    )
}

export default HomePage
