import { Button, FormControl, FormErrorMessage, Heading, HStack, VStack } from '@chakra-ui/react'
import AddImageFields from '@defs/AddImagesFields'
import checkImageMapFieldsHaveUpdated from '@services/checkImageMapFieldUpdate'
import { ImageField } from '@services/getS3SignedUrl.service'
import uploadImage from '@services/uploadImage.service'
import { useStytch } from '@stytch/react'
import { SessionTokens } from '@stytch/vanilla-js'
import generateProviderImageKey from '@utils/generateProviderImageKey'
import FormSubmitProgression from '@v1_atoms/FormSubmitProgression/FormSubmitProgression.component'
import ImageUploadBox from '@v1_atoms/ImageUploadBox/ImageUploadBox.component'
import EventQueryingPage, { EventWithImages } from '@v1_hocs/EventQueryingPage.component'
import ProtectedPage from '@v1_hocs/ProtectedPage.component'
import ImageUploadFormSection from '@v1_molecules/ImageUploadFormSection/ImageUploadFormSection.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import _ from 'lodash'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

enum ImagesLoadingStatus {
    NOT_LOADING = 'not_loading',
    UPLOADING_IMAGES = 'uploading_images',
    VERIFYING_IMAGES = 'verifying_images',
}

function AddEventImagesContents({ data }: { data: EventWithImages }) {
    const stytch = useStytch()
    const navigate = useNavigate()

    const methods = useForm<AddImageFields>({})

    const { id: eventId, providers, images } = data

    const [imagesUploadingStatus, setImagesUploadingStatus] = useState<ImagesLoadingStatus>(
        ImagesLoadingStatus.NOT_LOADING,
    )

    const [totalToUpload, setTotalToUpload] = useState(0)
    const [totalToVerify, setTotalToVerify] = useState(0)
    const [totalUploaded, setTotalUploaded] = useState(0)
    const [totalVerified, setTotalVerified] = useState(0)

    const onSubmit = async (formData: AddImageFields) => {
        setImagesUploadingStatus(ImagesLoadingStatus.UPLOADING_IMAGES)

        let tokens: SessionTokens | null

        try {
            tokens = stytch.session.getTokens()
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error('Error getting tokens. ERROR: ', err)
            // TODO redirect to login and then back to the current page
            // eslint-disable-next-line no-alert
            alert('Failed to get your session details from our authentication provider')
            return
        }

        if (!tokens) {
            // eslint-disable-next-line no-console
            console.error('No tokens returned')
            // TODO redirect to login and then back to the current page
            // eslint-disable-next-line no-alert
            alert('Failed to get your session details from our authentication provider')
            return
        }

        const uploadFields: {
            field: ImageField
            file: File | undefined
        }[] = [
            {
                field: 'primary',
                file: formData.primary[0],
            },
            {
                field: 'confirm/big',
                file: formData.confirmBig[0],
            },
            {
                field: 'confirm/medium',
                file: formData.confirmMedium[0],
            },
            {
                field: 'confirm/small',
                file: formData.confirmSmall[0],
            },
            {
                field: 'organiser',
                file: formData.organiser[0],
            },
            {
                field: 'thank-you',
                file: formData.thankYou[0],
            },
            ...(formData.providers?.map((item, idx) => ({
                field: `providers/${generateProviderImageKey({
                    name: providers[idx].name,
                    idx,
                })}` as ImageField,
                file: item[0],
            })) ?? []),
        ]

        const fieldsWithFileChanges = uploadFields.filter(({ file }) => file)

        if (fieldsWithFileChanges.length === 0) {
            // TODO - some kind of error

            // eslint-disable-next-line no-alert
            alert('No images uploaded')
            setImagesUploadingStatus(ImagesLoadingStatus.NOT_LOADING)
            return
        }

        setTotalToUpload(fieldsWithFileChanges.length)
        setTotalUploaded(0)

        const fieldsThatFailedToUpload: ImageField[] = []
        const fieldsToVerify: ImageField[] = []

        // eslint-disable-next-line no-restricted-syntax
        for (const change of fieldsWithFileChanges) {
            // eslint-disable-next-line no-await-in-loop
            const result = await uploadImage({
                eventId,
                field: change.field,
                file: change.file!,
                authToken: tokens.session_jwt,
            })

            if (result.success) {
                setTotalUploaded(totalUploaded + 1)
                fieldsToVerify.push(change.field)
            } else {
                fieldsThatFailedToUpload.push(change.field)
            }
        }

        setTotalToVerify(fieldsToVerify.length)
        setTotalVerified(0)

        const verifiedFields: ImageField[] = []

        await checkImageMapFieldsHaveUpdated({
            eventId,
            fields: fieldsToVerify,
            originalImageMap: images,
            maxRetries: 30,
            onFieldVerified: (field: ImageField) => {
                verifiedFields.push(field)
                setTotalVerified(totalVerified + 1)
            },
        })

        const fieldsThatFailedToVerify: ImageField[] = fieldsToVerify.filter(
            (field) => !verifiedFields.includes(field),
        )

        setImagesUploadingStatus(ImagesLoadingStatus.NOT_LOADING)

        if (fieldsThatFailedToUpload.length === 0 && fieldsThatFailedToVerify.length === 0) {
            navigate(`/events/${eventId}`)
            return
        }

        // TODO make this a nice error not a shitty alert

        if (fieldsThatFailedToUpload.length > 0) {
            // eslint-disable-next-line no-alert
            alert(
                `something went wrong uploading the following images: ${fieldsThatFailedToUpload.join(
                    ', ',
                )}. Try again`,
            )
        }

        if (fieldsThatFailedToVerify.length > 0) {
            // eslint-disable-next-line no-alert
            alert(
                `something went wrong checking the following images were uploaded: ${fieldsThatFailedToVerify.join(
                    ', ',
                )}. Try again`,
            )
        }
    }

    return (
        <FormProvider {...methods}>
            <VStack>
                <NavHeading menuColour="green" headingText="Turbafi" />
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <VStack spacing={8} maxW="800px" textColor="plejGreen.500" alignItems="start">
                        <Heading as="h2">Add images for your event</Heading>
                        <FormControl isInvalid={!!methods.formState.errors.primary}>
                            <ImageUploadFormSection
                                sectionName="primary"
                                description="This image will be the image used as the thumbnail for you event on the events page and also be the background image people see when clicking into your event. Make sure its a good size to remain crisp on larger screen sizes."
                            >
                                <FormErrorMessage>Primary image is required</FormErrorMessage>
                                <ImageUploadBox name="primary" existingImageSrc={images.primary} />
                            </ImageUploadFormSection>
                        </FormControl>
                        <FormControl
                            isInvalid={
                                !!methods.formState.errors.confirmBig ||
                                !!methods.formState.errors.confirmMedium ||
                                !!methods.formState.errors.confirmSmall
                            }
                        >
                            <ImageUploadFormSection
                                sectionName="confirm and pay"
                                description="These images will be shown on the page where the customer selects which of the ticket options they wish to attend using"
                            >
                                <FormErrorMessage>
                                    All three confirm images are required
                                </FormErrorMessage>
                                <HStack w="full">
                                    <ImageUploadBox
                                        name="confirmBig"
                                        existingImageSrc={images.confirm?.big}
                                    />
                                    <ImageUploadBox
                                        name="confirmMedium"
                                        existingImageSrc={images.confirm?.medium}
                                    />
                                    <ImageUploadBox
                                        name="confirmSmall"
                                        existingImageSrc={images.confirm?.small}
                                    />
                                </HStack>
                            </ImageUploadFormSection>
                        </FormControl>
                        <FormControl isInvalid={!!methods.formState.errors.thankYou}>
                            <ImageUploadFormSection
                                sectionName="thank you"
                                description="This image will be the image shown when a customer has pledged to attend your event."
                            >
                                <FormErrorMessage>Thank you image is required</FormErrorMessage>
                                <ImageUploadBox
                                    name="thankYou"
                                    existingImageSrc={images['thank-you']}
                                />
                            </ImageUploadFormSection>
                        </FormControl>
                        <FormControl isInvalid={!!methods.formState.errors.organiser}>
                            <ImageUploadFormSection
                                sectionName="organiser"
                                description="This image will be the image shown as the avatar image for the organiser of this event."
                            >
                                <FormErrorMessage>Organiser image is required</FormErrorMessage>
                                <ImageUploadBox
                                    name="organiser"
                                    existingImageSrc={images.organiser}
                                />
                            </ImageUploadFormSection>
                        </FormControl>
                        <ImageUploadFormSection
                            sectionName="providers"
                            description="This image will be the image shown as the avatar image for the organiser of this event."
                        >
                            {providers.map(({ name }, idx) => (
                                <FormControl
                                    key={`provider-image-${name}`}
                                    isInvalid={
                                        !!_.get(methods.formState.errors, `providers.${idx}`)
                                    }
                                >
                                    <VStack w="full" mt={4} spacing={4} alignItems="start">
                                        <Heading
                                            as="h4"
                                            fontSize="xl"
                                            mb={2}
                                            textTransform="capitalize"
                                        >
                                            {name}
                                        </Heading>
                                        <FormErrorMessage>
                                            {name} image is required
                                        </FormErrorMessage>
                                        <ImageUploadBox
                                            name={`providers.${idx}`}
                                            existingImageSrc={
                                                images.providers
                                                    ? images.providers[
                                                          generateProviderImageKey({ name, idx })
                                                      ]
                                                    : undefined
                                            }
                                        />
                                    </VStack>
                                </FormControl>
                            ))}
                        </ImageUploadFormSection>
                    </VStack>
                    <Button
                        type="submit"
                        isLoading={imagesUploadingStatus !== ImagesLoadingStatus.NOT_LOADING}
                        my={8}
                    >
                        Add images
                    </Button>
                    {imagesUploadingStatus !== ImagesLoadingStatus.NOT_LOADING ? (
                        <FormSubmitProgression
                            text={
                                imagesUploadingStatus === ImagesLoadingStatus.UPLOADING_IMAGES
                                    ? 'Uploading images...'
                                    : 'Checking images...'
                            }
                            completed={
                                imagesUploadingStatus === ImagesLoadingStatus.UPLOADING_IMAGES
                                    ? totalUploaded
                                    : totalVerified
                            }
                            toComplete={
                                imagesUploadingStatus === ImagesLoadingStatus.UPLOADING_IMAGES
                                    ? totalToUpload
                                    : totalToVerify
                            }
                        />
                    ) : null}
                </form>
            </VStack>
        </FormProvider>
    )
}

export default function AddEventImages() {
    return (
        <EventQueryingPage>
            {(data: EventWithImages) => (
                <ProtectedPage>
                    <AddEventImagesContents data={data} />
                </ProtectedPage>
            )}
        </EventQueryingPage>
    )
}
