import { Heading, Text, VStack } from '@chakra-ui/react'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'

export default function NotFound() {
    return (
        <VStack spacing="6" color="plejGreen.500" bg="plejGrey.300" w="100vw" minH="100vh">
            <NavHeading menuColour="green" headingText="Turbafi" />

            <Heading>Error 404</Heading>
            <Text>Page not found</Text>
        </VStack>
    )
}
