import Result from '@defs/Result'
import axios from 'axios'
import {
    InitialiseOrganiserMetadataBody,
    InitialiseOrganiserMetadataResponse,
} from '@commonwealthventures/stytch-auth-api-models'

export default async function initialiseOrganiserMetadata(
    organiserName: string,
    jwt: string,
): Promise<Result<InitialiseOrganiserMetadataResponse>> {
    try {
        const body: InitialiseOrganiserMetadataBody = {
            organiserName,
        }

        const result = await axios.post<InitialiseOrganiserMetadataResponse>(
            `${process.env.REACT_APP_STYTCH_API_URL}/metadata/organiser`,
            body,
            {
                headers: {
                    'x-API-Key': process.env.REACT_APP_STYTCH_API_KEY!,
                    Authorization: jwt,
                },
            },
        )

        return {
            success: true,
            value: result.data,
        }
    } catch (err) {
        return {
            success: false,
            error: 'Failed to configure metadata',
        }
    }
}
