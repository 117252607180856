import {
    Button,
    Heading,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useClipboard,
    VStack,
} from '@chakra-ui/react'
import DefaultRedirectOnLogin from '@constants/DefaultRedirectOnLogin'
import RecoveryCodesRoute from '@constants/RecoveryCodesRoute'
import { useStytch, useStytchUser } from '@stytch/react'
import { StytchAPIError, StytchError, TOTPCreateResponse } from '@stytch/vanilla-js'
import ProtectedPage from '@v1_hocs/ProtectedPage.component'
import AuthenticateTotp from '@v1_molecules/AuthenticateTotp/AuthenticateTotp.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import { useEffect, useRef, useState } from 'react'
import { MdCheck, MdOutlineContentCopy } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'

type StoredTotp = {
    account: string
    qrCode: string
    secret: string
    expiration: number
}

function ConfiguredMfaBody() {
    const stytch = useStytch()
    const { user } = useStytchUser()
    const navigate = useNavigate()
    const { onCopy, hasCopied, setValue } = useClipboard('')

    const requestedQrCode = useRef(false)
    const [qrCode, setQrCode] = useState<string>()
    const [totpSecret, setTotpSecret] = useState<string>()

    const [pageLoadError, setPageLoadError] = useState<string | undefined>()

    const getQrCodeFromStorage = () => {
        const storedTotp = localStorage.getItem('totp')
        if (storedTotp) {
            const parsedTotp = JSON.parse(storedTotp) as StoredTotp

            if (parsedTotp.account !== user!.user_id) {
                return false
            }

            if (parsedTotp.expiration >= Date.now()) {
                setTotpSecret(parsedTotp.secret)
                setQrCode(parsedTotp.qrCode)
                return true
            }
        }

        return false
    }

    const checkIfUserHasTotpSetupAlready = () => {
        if (user!.totps.length > 0) {
            const verifiedTotp = user!.totps.find(({ verified }) => verified)

            if (verifiedTotp) {
                // this is a protected link so if they have already got a valid TOTP session
                // they will stay there otherwise they'll be sent to the challenge MFA page

                navigate(DefaultRedirectOnLogin)
                return true
            }

            // if it is unverified then we need to call the generate method
        }

        return false
    }

    const generateQrCode = async () => {
        let totpResponse: TOTPCreateResponse

        try {
            totpResponse = await stytch.totps.create({ expiration_minutes: 60 })
        } catch (err) {
            const defaultError = 'Something went wrong, please refresh and try again'
            const castError = err as StytchError

            if (!('error_type' in castError)) {
                setPageLoadError(defaultError)
                return
            }

            const apiError = castError as StytchAPIError

            if (apiError.error_type === 'active_totp_exists') {
                // should never end up here as previous call to user should catch it
                navigate(DefaultRedirectOnLogin)
                return
            }

            if (apiError.error_type === 'pending_totp_exists') {
                setPageLoadError(
                    'You have already generated a QR code for setting up TOTP, if you have changed devices since please return to the original device and try again. Otherwise you will need to return an hour after making your original request',
                )
                return
            }

            setPageLoadError(defaultError)
            return
        }

        setQrCode(totpResponse.qr_code)
        setTotpSecret(totpResponse.secret)

        const expiration = new Date()
        expiration.setMinutes(expiration.getMinutes() + 60)

        const storedTotp: StoredTotp = {
            qrCode: totpResponse.qr_code,
            secret: totpResponse.secret,
            expiration: expiration.getTime(),
            account: user!.user_id,
        }
        localStorage.setItem('totp', JSON.stringify(storedTotp))
    }

    useEffect(() => {
        const qrCodeFoundInStorage = getQrCodeFromStorage()
        const userAlreadyTotpSetup = checkIfUserHasTotpSetupAlready()

        if (qrCodeFoundInStorage || userAlreadyTotpSetup) {
            return
        }

        if (!requestedQrCode.current) {
            requestedQrCode.current = true
            generateQrCode()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (totpSecret) {
            setValue(totpSecret)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totpSecret])

    if (pageLoadError) {
        return (
            <VStack w="full">
                <NavHeading menuColour="green" headingText="Turbafi" />

                <VStack
                    spacing={8}
                    maxW="800px"
                    w="full"
                    textColor="plejGreen.500"
                    alignItems="start"
                >
                    <Heading as="h2">Configure a TOTP</Heading>

                    <Text color="red.600">{pageLoadError}</Text>
                </VStack>
            </VStack>
        )
    }

    return (
        <VStack w="full">
            <NavHeading menuColour="green" headingText="Turbafi" />

            <VStack
                spacing={8}
                maxW="800px"
                w="full"
                textColor="plejGreen.500"
                alignItems="start"
                mb="6"
            >
                <Heading as="h2">Configure a TOTP</Heading>
                <Text>
                    Setting up TOTP (Time-Based One-Time Password) adds an extra layer of security
                    to your account.
                </Text>
                <Text>
                    Here&apos;s how it works: after setting it up, when you click the email link to
                    log in, you&apos;ll also be asked to enter a unique code from an app on your
                    phone (like Google Authenticator or Authy). This code changes every 30 seconds,
                    so even if someone gets access to your email, they won&apos;t be able to log in
                    to Turbafi without this code. It&apos;s easy to set up and helps keep your
                    account extra secure!
                </Text>

                <Heading as="h3">Step 1: Download a TOTP app to your phone</Heading>
                <Text>
                    You only need to do this if you haven&apos; got one already. We recommend Google
                    Authenticator as its easy to setup but there are plenty of other options out
                    there.
                </Text>

                <Heading as="h3">Step 2: Scan this QR code</Heading>
                <VStack w="full" spacing={6} alignItems="start">
                    <VStack w="full" spacing={2} alignItems="start">
                        <Text>Using your TOTP authenticator app scan this QR code</Text>

                        <Image my="-2" src={qrCode} alt="QR Code for configuring TOTP" />
                    </VStack>

                    <VStack w="full" spacing={2} alignItems="start">
                        <Text>Alternatively manually enter this secret into your app</Text>
                        <InputGroup size="lg">
                            <Input type="text" value={totpSecret} pr="4.5rem" readOnly />
                            <InputRightElement w="4.5rem" mr={5}>
                                <Button
                                    h="1.9rem"
                                    px={12}
                                    py={2}
                                    borderRadius={4}
                                    size="sm"
                                    leftIcon={hasCopied ? <MdCheck /> : <MdOutlineContentCopy />}
                                    onClick={onCopy}
                                    colorScheme="plejGrey"
                                    color="plejGreen.500"
                                    fontWeight="medium"
                                >
                                    {hasCopied ? 'Copied!' : 'Copy'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                    </VStack>
                </VStack>

                <Heading as="h3">Step 3: Enter the code shown by your app</Heading>

                <Text>
                    Enter the 6-digit verification code generated by your authenticator app.
                </Text>

                <AuthenticateTotp redirectTo={RecoveryCodesRoute} isSetupProcess />
            </VStack>
        </VStack>
    )
}

export default function ConfigureMfa() {
    return (
        <ProtectedPage checkMfa={false} requireSmsSetup={false}>
            <ConfiguredMfaBody />
        </ProtectedPage>
    )
}
