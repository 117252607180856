import { Box, Button, Heading, Text, VStack } from '@chakra-ui/react'
import { AdminCreateFundCurrency } from '@commonwealthventures/poc-admin-api-models'
import { CurrencyV1 } from '@commonwealthventures/poc-readstore-v1-models'
import CreateEventFields from '@defs/CreateEventFields'
import createFund from '@services/createFund.service'
import { useStytch, useStytchUser } from '@stytch/react'
import { SessionTokens } from '@stytch/vanilla-js'
import ProtectedPage from '@v1_hocs/ProtectedPage.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import AdditionalInformationForm, {
    newSection,
} from '@v1_templates/AdditionalInformationForm/AdditionalInformationForm.component'
import EventDetailsForm from '@v1_templates/EventDetailsForm/EventDetailsForm.component'
import FundDetailsForm from '@v1_templates/FundDetailsForm/FundDetailsForm.component'
import ProvidersForm, { newProvider } from '@v1_templates/ProvidersForm/ProvidersForm.component'
import TicketDetailsForm, {
    newTicketOption,
} from '@v1_templates/TicketDetailsForm/TicketDetailsForm.component'
import VenueDetailsForm from '@v1_templates/VenueDetailsForm/VenueDetailsForm.component'
import _ from 'lodash'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

const defaultWhatsIncluded: CreateEventFields['whatsIncluded'][number] = { value: '' }
const defaultWhatsNotIncluded: CreateEventFields['whatsNotIncluded'][number] = { value: '' }
const defaultAdditionalInfo = _.cloneDeep(newSection)
const defaultAddressLines: CreateEventFields['addressLines'][number] = { value: '' }
const defaultProvider = _.cloneDeep(newProvider)
const defaultCurrency = CurrencyV1.GBP
const defaultTicketOptions = newTicketOption

const defaultValues: Omit<Partial<CreateEventFields>, 'ticketOptions'> & {
    ticketOptions?: Pick<CreateEventFields['ticketOptions'][number], 'name'>[]
} = {
    whatsIncluded: [defaultWhatsIncluded],
    whatsNotIncluded: [defaultWhatsNotIncluded],
    additionalInfo: [defaultAdditionalInfo],
    addressLines: [defaultAddressLines],
    providers: [defaultProvider],
    currency: defaultCurrency,
    ticketOptions: [defaultTicketOptions],
}

function CreateEventPageBody() {
    const stytch = useStytch()
    const { user } = useStytchUser()
    const navigate = useNavigate()
    const methods = useForm<CreateEventFields>({
        mode: 'onBlur',
        defaultValues,
    })
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitError, setSubmitError] = useState<string | undefined>(undefined)

    if (!user) {
        if (!user) {
            return <Text>Loading...</Text>
        }
    }

    const onSubmit = async (formData: CreateEventFields) => {
        setSubmitError(undefined)
        setIsSubmitting(true)

        let tokens: SessionTokens | null

        try {
            tokens = stytch.session.getTokens()
        } catch (err) {
            setSubmitError('Failed to get your session details from our authentication provider')
            return
        }

        if (!tokens) {
            setSubmitError('Failed to get your session details from our authentication provider')
            return
        }

        const trustedMetadata = user!.trusted_metadata! as {
            organiserDetails: {
                id: string
                name: string
            }
        }

        const {
            success,
            value: id,
            error,
        } = await createFund(
            {
                organiserId: trustedMetadata.organiserDetails.id,
                fundingDeadline: new Date(formData.fundingDeadline).toISOString(),
                fundingThreshold:
                    typeof formData.fundingThreshold === 'string'
                        ? Number.parseInt(formData.fundingThreshold, 10)
                        : formData.fundingThreshold,
                traditionalPurchaseDeadline: new Date(
                    formData.traditionalPurchaseDeadline,
                ).toISOString(),
                maximumPlacesAvailable:
                    typeof formData.maxPlaces === 'string'
                        ? Number.parseInt(formData.maxPlaces, 10)
                        : formData.maxPlaces,
                eventDetails: {
                    title: formData.title,
                    summary: formData.summary,
                    detailedOverview: formData.detailedOverview,
                    date: {
                        startDate: new Date(formData.startDate).toISOString(),
                        endDate: new Date(formData.endDate).toISOString(),
                    },
                    venue: {
                        streetAddress: formData.addressLines.map(({ value }) => value),
                        city: formData.city,
                        postCode: formData.postcode,
                        country: formData.country,
                    },
                    ticketOptions: formData.ticketOptions.map((option) => ({
                        name: option.name,
                        price: Math.round((option.priceInPence / 100 + Number.EPSILON) * 100) / 100,
                        currency: formData.currency as unknown as AdminCreateFundCurrency,
                    })),
                    organiser: {
                        name: trustedMetadata.organiserDetails.name,
                    },
                    providers: formData.providers.map((provider) => ({
                        name: provider.name,
                        bio: provider.bio,
                    })),
                    additionalInformation: formData.additionalInfo.map((section) => ({
                        title: section.title,
                        subSections: section.subSections.map((subSection) => ({
                            sectionTitle:
                                subSection.subSectionTitle &&
                                subSection.subSectionTitle.trim() !== ''
                                    ? subSection.subSectionTitle
                                    : undefined,
                            text: subSection.text.map(({ value }) => value),
                        })),
                    })),
                    whatsIncluded: formData.whatsIncluded.map(({ value }) => value),
                    whatsNotIncluded: formData.whatsNotIncluded.map(({ value }) => value),
                },
            },
            tokens.session_jwt,
        )

        setIsSubmitting(false)

        if (!success) {
            setSubmitError(`Something went wrong creating your event. ${error}. Try again`)
            return
        }

        navigate(`/organiser/create-event/${id}/images`)
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <VStack>
                    <NavHeading menuColour="green" headingText="Turbafi" />
                    <VStack spacing={8} w="full">
                        <VStack
                            spacing={8}
                            w="full"
                            maxW="800px"
                            alignItems="start"
                            textColor="plejGreen.500"
                        >
                            <Heading as="h2">Create new fund/event</Heading>
                        </VStack>
                        <VStack bg="white" alignItems="center" w="full">
                            <FundDetailsForm variant="white" />
                        </VStack>
                        <VStack pt={8} pb={8} bg="plejGreen.500" alignItems="center" w="full">
                            <EventDetailsForm variant="green" />
                        </VStack>
                        <VStack bg="white" textColor="plejGreen.500" alignItems="center" w="full">
                            <AdditionalInformationForm variant="white" />
                        </VStack>
                        <VStack
                            textColor="white"
                            bg="plejGreen.500"
                            alignItems="center"
                            w="full"
                            pt={8}
                            pb={8}
                        >
                            <VenueDetailsForm variant="green" />
                        </VStack>
                        <VStack textColor="plejGreen.500" bg="white" alignItems="center" w="full">
                            <ProvidersForm variant="white" />
                        </VStack>
                        <VStack
                            textColor="white"
                            bg="plejGreen.500"
                            alignItems="center"
                            w="full"
                            pt={8}
                            pb={8}
                        >
                            <TicketDetailsForm variant="green" />
                        </VStack>
                    </VStack>
                    <Button
                        maxW="800px"
                        my={6}
                        isLoading={isSubmitting}
                        isDisabled={Object.keys(methods.formState.errors).length !== 0}
                        type="submit"
                    >
                        Create {Object.keys(methods.formState.errors)}
                    </Button>
                    {submitError ? (
                        <Box
                            maxW="800px"
                            w="full"
                            borderColor="red.600"
                            borderWidth="2pt"
                            borderStyle="solid"
                            backgroundColor="red.100"
                            color="red.600"
                            borderRadius={6}
                            padding={2}
                            mb={6}
                        >
                            <Text>{submitError}</Text>
                        </Box>
                    ) : null}
                </VStack>
            </form>
        </FormProvider>
    )
}

export default function CreateEventPage() {
    return (
        <ProtectedPage>
            <CreateEventPageBody />
        </ProtectedPage>
    )
}
