import { Button, FormControl, FormErrorMessage, Input, VStack } from '@chakra-ui/react'
import ChallengeSmsRoute from '@constants/ChallengeSmsRoute'
import { useStytch } from '@stytch/react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

function RecoverTotp() {
    const stytch = useStytch()
    const navigate = useNavigate()

    const { handleSubmit, formState, register } = useForm<{ recoveryCode: string }>({
        mode: 'onBlur',
    })
    const [recoverTotpError, setRecoverTotpError] = useState<string | undefined>()

    const recoverTotp = async (formData: { recoveryCode: string }) => {
        try {
            await stytch.totps.recover({
                recovery_code: formData.recoveryCode,
                session_duration_minutes: 60,
            })
        } catch (err) {
            const errorMessage =
                'Failed to recover TOTP. Please check the code you entered was a valid recovery code'
            setRecoverTotpError(errorMessage)
            return
        }

        setRecoverTotpError(undefined)
        navigate(ChallengeSmsRoute)
    }

    return (
        <form onSubmit={handleSubmit(recoverTotp)}>
            <VStack>
                <FormControl isInvalid={!!formState.errors.recoveryCode || !!recoverTotpError}>
                    <Input
                        w="full"
                        type="text"
                        variant="createEventGreen"
                        {...register('recoveryCode', {
                            required: true,
                        })}
                    />
                    <FormErrorMessage>
                        {recoverTotpError || (formState.errors.recoveryCode?.message ?? '')}
                    </FormErrorMessage>
                </FormControl>
                <Button type="submit">Recover</Button>
            </VStack>
        </form>
    )
}

export default RecoverTotp
