function formatSingleDateHoursAndMins(date: Date) {
    return `${date.getHours().toString().padStart(2, '0')}:${date
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
}

export default function formatHoursAndMinutes({
    startDate,
    endDate,
}: {
    startDate: string
    endDate: string
}) {
    const startDateAsDate = new Date(startDate)
    const endDateAsDate = new Date(endDate)

    return `${formatSingleDateHoursAndMins(startDateAsDate)} - ${formatSingleDateHoursAndMins(
        endDateAsDate,
    )}`
}
