import { CurrencyV1 } from '@commonwealthventures/poc-readstore-v1-models'

export default function formatPriceInPence({
    price,
    currency,
}: {
    price: number
    currency: CurrencyV1
}) {
    const formatter = new Intl.NumberFormat('en-GB', {
        style: 'currency',
        currency,
        maximumFractionDigits: 2,
    })

    return formatter.format(price / 100)
}
