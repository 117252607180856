import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Heading,
    IconButton,
    Input,
    Text,
    VStack,
} from '@chakra-ui/react'
import { useStytch } from '@stytch/react'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import { BaseSyntheticEvent, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { MdArrowBack } from 'react-icons/md'

function LoginOrSignUp() {
    const stytch = useStytch()

    const methods = useForm<{ email: string }>()

    const [checkEmailsMessage, setCheckEmailsMessage] = useState<string | undefined>()
    const [magicLinkError, setMagicLinkError] = useState<string | undefined>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const login = async (email: string) => {
        setIsLoading(true)

        try {
            await stytch.magicLinks.email.send(email, {
                login_magic_link_url: `${window.location.origin}/authenticate`,
                login_expiration_minutes: 180,
            })
        } catch (err) {
            setMagicLinkError('Something went wrong, please try again')

            setIsLoading(false)

            return
        }

        setCheckEmailsMessage(
            "If you have an account we will send you an email with a link to login. If you've not already registered with us you will need to go back and click sign up",
        )
        setIsLoading(false)
    }

    const signUp = async (email: string) => {
        setIsLoading(true)

        try {
            await stytch.magicLinks.email.loginOrCreate(email, {
                signup_magic_link_url: `${window.location.origin}/authenticate`,
            })
        } catch (err) {
            setMagicLinkError('Something went wrong, please try again')

            setIsLoading(false)
            return
        }

        setCheckEmailsMessage(
            'Thanks for signing up! Check your emails for a link from us to verify your email',
        )
        setIsLoading(false)
    }

    const sendEmailMagicLink = async (
        formData: { email: string },
        e: BaseSyntheticEvent | undefined,
    ) => {
        if (!e) {
            setMagicLinkError('Error submitting form, please reload and try again')
        }

        const submitter = (e!.nativeEvent as { submitter: HTMLButtonElement }).submitter.name

        if (submitter === 'login') {
            await login(formData.email)
            return
        }

        await signUp(formData.email)
    }

    return (
        <FormProvider {...methods}>
            <VStack w="full">
                <NavHeading menuColour="green" headingText="Turbafi" />
                <form
                    style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                    onSubmit={methods.handleSubmit(sendEmailMagicLink)}
                >
                    <VStack
                        spacing={8}
                        maxW="600px"
                        w="full"
                        textColor="plejGreen.500"
                        alignItems="start"
                    >
                        <Heading as="h2">Login / Sign up</Heading>
                        {checkEmailsMessage ? (
                            <VStack alignItems="start">
                                <IconButton
                                    aria-label="Go back to login form"
                                    icon={<MdArrowBack />}
                                    onClick={() => setCheckEmailsMessage(undefined)}
                                    variant="link"
                                    fontSize="2xl"
                                    w="2em"
                                    marginLeft={-4}
                                />
                                <Text>{checkEmailsMessage}</Text>
                            </VStack>
                        ) : (
                            <>
                                <FormControl isInvalid={!!methods.formState.errors.email}>
                                    <FormLabel>Email:</FormLabel>
                                    <Input
                                        w="full"
                                        type="email"
                                        variant="createEventGreen"
                                        {...methods.register('email', {
                                            required: true,
                                        })}
                                    />
                                    <FormErrorMessage>You must enter an email</FormErrorMessage>
                                </FormControl>
                                {magicLinkError ? (
                                    <Box
                                        maxW="800px"
                                        w="full"
                                        borderColor="red.600"
                                        borderWidth="2pt"
                                        borderStyle="solid"
                                        backgroundColor="red.100"
                                        color="red.600"
                                        borderRadius={6}
                                        padding={2}
                                        mb={6}
                                    >
                                        <Text>{magicLinkError}</Text>
                                    </Box>
                                ) : null}
                                <Button name="login" isLoading={isLoading} type="submit">
                                    Login
                                </Button>
                                <Button
                                    name="signUp"
                                    isLoading={isLoading}
                                    type="submit"
                                    variant="outline"
                                    _hover={{ backgroundColor: 'plejGreen.100' }}
                                >
                                    Sign up
                                </Button>
                            </>
                        )}
                    </VStack>
                </form>
            </VStack>
        </FormProvider>
    )
}

export default LoginOrSignUp
