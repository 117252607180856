import { Button, Heading, Text, VStack } from '@chakra-ui/react'
import ChallengeMfaRoute from '@constants/ChallengeMfaRoute'
import ConfigureMfaRoute from '@constants/ConfigureMfaRoute'
import DefaultRedirectOnLogin from '@constants/DefaultRedirectOnLogin'
import ResetMfaRoute from '@constants/ResetMfaRoute'
import checkSessionHasBeenMfaChecked from '@helpers/authentication/checkSessionHasBeenMfaChecked'
import sendSms from '@services/sendSms.service'
import { useStytch, useStytchSession, useStytchUser } from '@stytch/react'
import { StytchUIClient } from '@stytch/vanilla-js'
import ProtectedPage from '@v1_hocs/ProtectedPage.component'
import AuthenticateOtp from '@v1_molecules/AuthenticateOtp/AuthenticateOtp.component'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import { useState } from 'react'
import { Navigate } from 'react-router-dom'

function ChallengeSmsBody() {
    const stytch = useStytch()
    const { user } = useStytchUser()
    const { session } = useStytchSession()

    const [sendingCode, setSendingCode] = useState(false)
    const [sendCodeError, setSendCodeError] = useState<string | undefined>()
    const [sentCodeMethodId, setSentCodeMethodId] = useState<string | undefined>()
    const [enableResend, setEnableResend] = useState(false)

    if (!user || !session) {
        return <Text>Loading...</Text>
    }

    const verifiedTotp = user.totps.find(({ verified }) => verified)

    if (!verifiedTotp) {
        return <Navigate to={ConfigureMfaRoute} />
    }

    const sessionMfaAuthed = checkSessionHasBeenMfaChecked(session!)

    if (sessionMfaAuthed) {
        return <Navigate to={DefaultRedirectOnLogin} />
    }

    const sessionInRecoveryMode = session!.authentication_factors.find(
        ({ delivery_method, type }) => delivery_method === 'recovery_code' && type === 'totp',
    )

    if (!sessionInRecoveryMode) {
        return <Navigate to={ChallengeMfaRoute} />
    }

    const verifiedSms = user.phone_numbers.find(({ verified }) => verified)

    if (!verifiedSms) {
        return (
            <VStack w="full">
                <NavHeading menuColour="green" headingText="Turbafi" />
                <VStack
                    spacing={8}
                    maxW="600px"
                    w="full"
                    textColor="plejGreen.500"
                    alignItems="start"
                >
                    <Heading as="h3">Cannot recover account</Heading>
                    <Text>
                        Unfortunately, since you do not have SMS configured for your account we are
                        unable to recover it.
                    </Text>
                </VStack>
            </VStack>
        )
    }

    const sendOtpCode = async () => {
        setSendingCode(true)

        const {
            success,
            value: methodId,
            error,
        } = await sendSms(stytch as StytchUIClient, verifiedSms.phone_number)

        setSendingCode(false)

        if (!success) {
            setSendCodeError(error)

            return
        }

        setSentCodeMethodId(methodId)
        setEnableResend(false)

        setTimeout(() => {
            if (sentCodeMethodId) {
                setEnableResend(true)
            }
        })
    }

    return (
        <VStack w="full">
            <NavHeading menuColour="green" headingText="Turbafi" />
            <VStack spacing={8} maxW="600px" w="full" textColor="plejGreen.500" alignItems="start">
                <Heading as="h2">Recover account</Heading>
                <Text>
                    You have logged in via a recovery code. This is a one time login and therefore
                    you cannot use this recovery code again. Remove it from your list.
                </Text>
                <Text>
                    To verify it is really you, we will send you a text containing a code to your
                    number registered with this account ending {verifiedSms.phone_number.slice(-4)}.
                </Text>

                {sentCodeMethodId ? (
                    <>
                        <AuthenticateOtp
                            redirectTo={ResetMfaRoute}
                            sendMethodId={sentCodeMethodId}
                        />
                        <Button
                            onClick={() => setSentCodeMethodId(undefined)}
                            variant="textButton"
                            size="md"
                            mt={-4}
                            disabled={!enableResend}
                        >
                            I did not receive a message
                        </Button>
                    </>
                ) : (
                    <>
                        {sendCodeError ? <Text color="red.500">{sendCodeError}</Text> : null}

                        <Button onClick={sendOtpCode} isLoading={sendingCode}>
                            Send code
                        </Button>
                    </>
                )}
            </VStack>
        </VStack>
    )
}

export default function ChallengeSms() {
    return (
        <ProtectedPage checkMfa={false} requireSmsSetup={false}>
            <ChallengeSmsBody />
        </ProtectedPage>
    )
}
