import Result from '@defs/Result'
import { useStytch } from '@stytch/react'
import AuthenticateCodeForm, {
    AuthenticateCodeFormData,
} from '@v1_atoms/AuthenticateCodeForm/AuthenticateCodeForm.component'
import { useNavigate } from 'react-router-dom'

interface AuthenticateTotpProps {
    redirectTo: string
    isSetupProcess: boolean
}

function AuthenticateTotp({ isSetupProcess, redirectTo }: AuthenticateTotpProps) {
    const stytch = useStytch()

    const navigate = useNavigate()

    const authenticateTotp = async (formData: AuthenticateCodeFormData): Promise<Result<void>> => {
        try {
            await stytch.totps.authenticate({
                totp_code: Object.values(formData.code).join(''),
                session_duration_minutes: 60,
            })

            navigate(redirectTo)
        } catch (err) {
            let errorMessage = 'Failed to authenticate TOTP. Wait for another code in your app'

            if (isSetupProcess) {
                errorMessage += ' or try scanning the QR code again'
            }

            return {
                success: false,
                error: errorMessage,
            }
        }

        return {
            success: true,
        }
    }

    return <AuthenticateCodeForm authenticate={authenticateTotp} />
}

export default AuthenticateTotp
