import { CircularProgress, CircularProgressLabel, Text, VStack } from '@chakra-ui/react'

export interface FormSubmitProgressionProps {
    text: string
    completed: number
    toComplete: number
}

function FormSubmitProgression({ text, completed, toComplete }: FormSubmitProgressionProps) {
    const percent = Math.ceil((completed / toComplete) * 100)

    return (
        <VStack
            w="100vw"
            h="100vh"
            position="fixed"
            left={0}
            top={0}
            justifyContent="center"
            alignContent="center"
            bg="plejGreen.500"
        >
            <CircularProgress
                value={completed}
                min={0}
                max={toComplete}
                color="white"
                textColor="white"
                trackColor="transparent"
                size="75px"
                fontSize="6xl"
            >
                <CircularProgressLabel>{percent}%</CircularProgressLabel>
            </CircularProgress>
            <Text color="white">{text}</Text>
        </VStack>
    )
}

export default FormSubmitProgression
