import { Heading, Link, Text, VStack } from '@chakra-ui/react'
import { useStytch, useStytchSession } from '@stytch/react'
import { MagicLinksAuthenticateResponse, StytchAPIError, StytchError } from '@stytch/vanilla-js'
import NavHeading from '@v1_organisms/NavHeading/NavHeading.component'
import { useEffect, useRef, useState } from 'react'
import { Navigate } from 'react-router-dom'
import ChallengeMfaRoute from '@constants/ChallengeMfaRoute'
import ConfigureMfaRoute from '@constants/ConfigureMfaRoute'
import DefaultRedirectOnLogin from '@constants/DefaultRedirectOnLogin'
import checkSessionHasBeenMfaChecked from '@helpers/authentication/checkSessionHasBeenMfaChecked'

function Authenticate() {
    const stytch = useStytch()
    const { session } = useStytchSession()

    const handlingAuthentication = useRef(false)

    const [nextRoute, setNextRoute] = useState<string | undefined>()

    const [authError, setAuthError] = useState<string | undefined>()

    const handleAuthentication = async () => {
        const token = new URLSearchParams(window.location.search).get('token')
        if (token) {
            let result: MagicLinksAuthenticateResponse

            try {
                result = await stytch.magicLinks.authenticate(token, {
                    session_duration_minutes: 60,
                })
            } catch (err) {
                const defaultError = 'An unexpected error occurred during login. Please try again'

                const castError = err as StytchError

                if (!('error_type' in castError)) {
                    setAuthError(defaultError)
                    return
                }

                const apiError = castError as StytchAPIError

                if (apiError.error_type === 'invalid_pkce_code_verifier') {
                    setAuthError(
                        "It looks like there's a verification issue with the login link. Please return to the login page and request a new link",
                    )
                } else if (apiError.error_type === 'invalid_token') {
                    setAuthError(
                        "The login link you used isn't valid. It may have expired or already been used. Please return to the login page and request a new link",
                    )
                } else if (apiError.error_type === 'invalid_token_docs') {
                    setAuthError(
                        'The login token appears invalid or outdated. It may have expired or already been used. Please return to the login page and request a new link',
                    )
                } else if (apiError.error_type === 'pkce_mismatch') {
                    setAuthError(
                        "We couldn't verify your login link. This can happen if the link is opened in a different browser or device than the one used initially. Try reopening the link in the original browser or return to the login page and request a new link",
                    )
                }

                return
            }

            if (result!.user.totps.length === 0) {
                setNextRoute(ConfigureMfaRoute)
            } else {
                setNextRoute(ChallengeMfaRoute)
            }
            return
        }

        setNextRoute('/login')
    }

    useEffect(() => {
        if (handlingAuthentication.current) {
            return
        }
        handlingAuthentication.current = true

        if (session) {
            const mfaSession = checkSessionHasBeenMfaChecked(session)

            if (mfaSession) {
                setNextRoute(DefaultRedirectOnLogin)
            } else {
                setNextRoute(ChallengeMfaRoute)
            }
        } else {
            handleAuthentication()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stytch, session])

    if (authError) {
        return (
            <VStack w="full">
                <NavHeading menuColour="green" headingText="Turbafi" />

                <VStack
                    spacing={8}
                    maxW="800px"
                    w="full"
                    textColor="plejGreen.500"
                    alignItems="start"
                >
                    <Heading as="h2">Something went wrong when logging you in</Heading>

                    <Text color="red.600">{authError}</Text>

                    <Link href="/login">Return to the login page</Link>
                </VStack>
            </VStack>
        )
    }

    if (nextRoute) {
        return <Navigate to={nextRoute} />
    }

    return <div>Loading...</div>
}

export default Authenticate
